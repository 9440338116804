import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  TextField,
  Autocomplete,
  Grid,
  Divider,
  Alert,
  ImageListItemBar,
  ImageListItem,
  ImageList,
  Tooltip,
} from '@mui/material';
import { Help, VerifiedUser } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { toast } from 'react-toastify';

import useRedux from '../../../../utils/hooks/useRedux';
import {
  errorChecker,
  findElement,
  findParentElement,
  findSectionAndGroupForElement,
  startsWithAny,
  updateSourceData,
} from '../../../../utils/helpers';
import {
  SELLER_FIRST_NAME,
  SSN_FIELDS,
  SELLER_LAST_NAME,
  BORROWER_FIRST_NAME,
  BORROWER_LAST_NAME,
  primaryDlFields,
  SOI_SECTION_ID,
  DL_NUMBER_FIELD,
  ID_MAPPING,
  NAME_OF_ACCOUNT_NAME,
  ROUTING_NAME,
  ACCOUNT_NAME,
  BANK_CITY_NAME,
  BANK_STATE_NAME,
  BANK_NAME_NAME,
  ROUTING_NAME_2,
  FIELDS_TO_DISABLE,
  TITLE_VESTED_NAME,
  FIELDS_CHECK_VALIDATIONS,
  LOAN_UPLOAD_NAME,
  DL_FIELD_NUMBER,
  BUYER_FULL_NAME_ID,
} from '../../../../utils/constants';
import { getSSN } from '../../../../services/order/order';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../Loader';
import { useTranslation } from 'react-i18next';
import MobyUploadModal from '../../../questions/home/MobyUploadModal';
import {
  addBankDetails,
  addDlDetails,
  addQuestionDetails,
} from '../../../../store/slices/questionSlice';
import { getQuestionDetails, verifyBankDetails } from '../../../../services/questions/home';
import { verifyElement } from '../../../../services/admin/participantForms';
// import { addUserConcent, createInvitation } from '../../../../services/questions/moby';

const TextFieldInput = ({ element, disabled, saveFormDataPHH }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { code, participantNo } = useParams();
  const selectedLanguage = useSelector((state) => state.questionSlice.languages)?.[code];
  const companyDetails = useSelector((state) => state.questionSlice.companyDetails)?.find(
    (company) => company.tx_id === code
  )?.company;
  const languageForLabel = selectedLanguage && selectedLanguage !== 'en' ? selectedLanguage : '';
  const translations = useSelector((state) => state.questionSlice.convertedTexts)?.find(
    (que) => que.tx_id === code
  );
  const {
    setFormValue,
    getFormValue,
    getErrorDetails,
    removeError,
    setError,
    setTouched,
    findIsTouched,
    getDocument,
  } = useRedux();
  const authData = useSelector((state) => state?.authSlice)?.[code];
  const isBuyer = authData?.participant_type_name?.toLowerCase().includes('buyer');
  const order_part_participant_id = useSelector(
    (state) => state.questionSlice.questionDetails
  )?.find((que) => que.tx_id === code)?.order_part_participant_id;
  const formValue = getFormValue(element.element_id);
  const { error, error_message, error_type } = getErrorDetails(element.element_id) || {
    error: false,
    error_message: '',
  };
  const qrDetails = useSelector((state) => state.questionSlice.qrDetails)?.[code];
  const question = useSelector((state) => state.questionSlice.questionDetails)?.find(
    (que) => que.tx_id === code
  )?.questions;
  const dlDetails = useSelector((state) => state.questionSlice.dlDetails)?.[code];
  const bankDetails = useSelector((state) => state.questionSlice.bankDetails)?.[code];
  const [value, setValue] = useState('');
  const [placeholders, setPlaceholders] = useState([]);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const buyerNameVal = () =>
    dlDetails?.transaction_response?.FirstName +
    ' ' +
    dlDetails?.transaction_response?.FirstSurname;
  const hiddenFields = [BANK_NAME_NAME, BANK_CITY_NAME, BANK_STATE_NAME];
  const dlInfoPopulate = (details) => details?.Approved || details?.DocExpired;

  // const disabledBankFields = useMemo(() => {
  //   let disabledFields = [];
  //   if (bankDetails) {
  //     for (const bankType in bankDetails) {
  //       if (bankDetails?.[bankType]?.associated_fields?.length) {
  //         disabledFields = [...disabledFields, ...bankDetails?.[bankType]?.associated_fields];
  //       }
  //     }
  //   }
  //   return disabledFields;
  // }, [bankDetails]);

  // const verifiedBankFields = useMemo(() => {
  //   let verifiedFields = [];
  //   if (bankDetails) {
  //     for (const bankType in bankDetails) {
  //       if (bankDetails?.[bankType]?.verified_field) {
  //         verifiedFields.push(bankDetails?.[bankType]?.verified_field);
  //       }
  //     }
  //   }
  //   return verifiedFields;
  // }, [bankDetails]);

  const isAdditionalParticipant = participantNo && participantNo != 1 ? true : false;

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      setIsMobile(true);
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsMobile(true);
    }
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  useEffect(() => {
    if (!isAdditionalParticipant && !isBuyer) {
      const section = question.sections.find((section) => section.section_id == SOI_SECTION_ID);
      let firstNameVal =
        getFormValue(SELLER_FIRST_NAME) ||
        findElement(section, SELLER_FIRST_NAME)?.user_value ||
        '';
      let lastNameVal =
        getFormValue(SELLER_LAST_NAME) || findElement(section, SELLER_LAST_NAME)?.user_value || '';
      let fName = '';
      let lName = '';

      if (
        dlDetails?.transaction_response?.FirstName &&
        dlDetails?.transaction_response?.FirstName?.split(' ')?.[0]
      ) {
        fName = dlDetails?.transaction_response?.FirstName?.split(' ')?.[0];
      }
      if (
        dlDetails?.transaction_response?.FirstSurname &&
        dlDetails?.transaction_response?.FirstSurname?.split(' ')?.[0]
      ) {
        lName = dlDetails?.transaction_response?.FirstSurname?.split(' ')?.[0];
      }
      let isVerifiedStatus =
        dlDetails?.status === 'approved' &&
        firstNameVal &&
        firstNameVal?.toString()?.trim()?.toLowerCase() === fName?.toLowerCase() &&
        lastNameVal &&
        lastNameVal?.toString()?.trim()?.toLowerCase() === lName?.toLowerCase();
      setIsVerified(isVerifiedStatus);
    }
  }, [getFormValue(SELLER_FIRST_NAME), getFormValue(SELLER_LAST_NAME), dlDetails]);

  const dataVal =
    formValue !== undefined ? formValue : element.user_value ? element.user_value : '';

  const mapping = {
    [SELLER_FIRST_NAME]: 'FirstName',
    [SELLER_LAST_NAME]: 'FirstSurname',
    [BORROWER_FIRST_NAME]: 'FirstName',
    [BORROWER_LAST_NAME]: 'FirstSurname',
  };

  useEffect(() => {
    if (dlDetails?.status === 'approved' && dataVal) {
      checkDlError();
    }
  }, [dataVal, dlDetails]);

  const checkDlError = () => {
    if (
      !isAdditionalParticipant &&
      [
        SELLER_FIRST_NAME,
        SELLER_LAST_NAME,
        BORROWER_FIRST_NAME,
        BORROWER_LAST_NAME,
        BUYER_FULL_NAME_ID,
      ].includes(element.element_id)
    ) {
      let valueToCompare = '';

      if (
        dlDetails?.transaction_response?.[mapping?.[element?.element_id]]?.length &&
        dlDetails?.transaction_response?.[mapping?.[element?.element_id]]?.split(' ')?.[0] &&
        !isBuyer
      ) {
        valueToCompare =
          dlDetails?.transaction_response?.[mapping?.[element?.element_id]]?.split(' ')?.[0];
      }

      if (isBuyer) {
        valueToCompare = buyerNameVal();
      }

      if (
        dataVal?.length &&
        valueToCompare?.length &&
        dataVal?.toString()?.trim()?.toLowerCase() !== valueToCompare?.toLowerCase() &&
        !dlDetails?.review?.[element.element_id]
      ) {
        setError({
          id: element.element_id,
          error: true,
          error_message: `The value you entered does not match the information on your uploaded government issued ID. Please correct it.`,
          type: 'warning',
        });
      } else {
        removeError(element.element_id);
      }
    }
    if (element.element_id === DL_NUMBER_FIELD && !isAdditionalParticipant) {
      if (
        dataVal &&
        dlDetails?.transaction_response?.IdentificationNumber &&
        dataVal !== dlDetails?.transaction_response?.IdentificationNumber
      ) {
        setError({
          id: element.element_id,
          error: true,
          error_message:
            'The value you entered does not match the information on your uploaded government issued ID. Please correct it.',
          type: 'warning',
        });
      } else {
        removeError(element.element_id);
      }
    }
  };

  const getQuestionData = async () => {
    setIsLoading(true);
    const queData = await getQuestionDetails(code, authData?.token);
    if (queData?.success) {
      dispatch(
        addQuestionDetails({
          tx_id: code,
          is_pdf_generated: queData?.response?.is_pdf_generated || false,
          questions: queData?.response?.question?.cached_questions_json?.question,
          template_id: queData?.response?.template_id,
          sources: queData?.response?.source || [],
          order_part_participant_id: queData?.response?.question?.id,
          documents: queData?.response?.additional_docs || {},
        })
      );
    } else {
      toast.error(queData?.message || 'Something went wrong!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (formValue !== undefined) setValue(formValue || null);
    else if (element.user_value) setValue(element.user_value);
    else if (SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))) getFullSSN();
  }, [formValue]);

  // useEffect(() => {
  //   const sourceData = fetchSourceData(element);
  //   const tempPlaceholders = sourceData?.map((src) => src.value);

  //   if (tempPlaceholders?.length) {
  //     if (
  //       (tempPlaceholders?.length === 1 || areAllElementsSame(tempPlaceholders)) &&
  //       !element.user_value
  //     ) {
  //       if (
  //         element?.element_name == 'core_assoication_dues' ||
  //         element?.element_name == 'core_association_dues2'
  //       ) {
  //         setFormValue({
  //           id: element?.element_id,
  //           value: tempPlaceholders[0].replace(/[^0-9.]/g, ''),
  //         });
  //         setValue(tempPlaceholders[0].replace(/[^0-9.]/g, ''));
  //         // setDisabledField(true);
  //       } else {
  //         setFormValue({ id: element?.element_id, value: tempPlaceholders[0] });
  //         setValue(tempPlaceholders[0]);
  //         // setDisabledField(true);
  //       }
  //     }
  //   }
  //   // setPlaceholders(tempPlaceholders);
  // }, [element?.element_id, element?.verification?.verification_source]);

  // for populating DL number
  useEffect(() => {
    if (!isAdditionalParticipant && element.element_id === DL_NUMBER_FIELD) {
      if (
        dlInfoPopulate(dlDetails?.transaction_response) &&
        dlDetails?.transaction_response?.IdentificationNumber
      ) {
        if (!dataVal) {
          setFormValue({
            id: element.element_id,
            value: dlDetails?.transaction_response?.IdentificationNumber,
          });
          setValue(dlDetails?.transaction_response?.IdentificationNumber);
          setTouched(element.element_id);
          removeError(element.element_id);
        } else if (dataVal !== dlDetails?.transaction_response?.IdentificationNumber) {
          setError({
            id: element.element_id,
            error: true,
            error_message:
              'The value you entered does not match the information on your uploaded government issued ID. Please correct it.',
            type: 'warning',
          });
        }
      }
    }

    // populate DL number for additional sellers
    if (
      isAdditionalParticipant &&
      +participantNo === +element?.participant_no &&
      element?.element_name?.startsWith(
        authData?.participant_type_name?.toLowerCase() + DL_FIELD_NUMBER
      )
    ) {
      if (
        dlInfoPopulate(dlDetails?.transaction_response) &&
        dlDetails?.transaction_response?.IdentificationNumber
      ) {
        if (!dataVal) {
          setFormValue({
            id: element.element_id,
            value: dlDetails?.transaction_response?.IdentificationNumber,
          });
          setValue(dlDetails?.transaction_response?.IdentificationNumber);
          setTouched(element.element_id);
          removeError(element.element_id);
        } else if (dataVal !== dlDetails?.transaction_response?.IdentificationNumber) {
          setError({
            id: element.element_id,
            error: true,
            error_message:
              'The value you entered does not match the information on your uploaded government issued ID. Please correct it.',
            type: 'warning',
          });
        }
      }
    }
  }, [element?.element_id, dlDetails, dataVal]);

  const getFullSSN = async () => {
    const isAdditionalParticipant =
      (element?.participant_number && element?.participant_number !== 1) ||
      element.element_name === 'core_spouse_ssn' ||
      element.element_name === 'borrower_spouse_ssn'
        ? true
        : false;
    let payload = { show_number: true };
    if (isAdditionalParticipant) {
      if (!element?.ssn_part_1?.length || !element?.ssn_part_2?.length) return;
      payload.ssn_part_1 = element?.ssn_part_1;
      payload.ssn_part_2 = element?.ssn_part_2;
    } else {
      payload.order_part_participant_id = order_part_participant_id;
      payload.participant_no = 1;
    }
    const res = await getSSN(payload, authData?.token);
    if (res.success && res?.response?.full_number) {
      setFormValue({ id: element?.element_id, value: res?.response?.full_number });
      setValue(res?.response?.full_number);
    }
  };

  const getElementMask = (element) => {
    let mask = element?.sample_input || '',
      mask_char = '_';

    if (mask === '$') {
      mask = '$999999999999999999999999999999999';
      mask_char = null;
    }

    switch (element.element_name) {
      // Tax ID Number (##-#######)
      case 'core_tid':
      case 'core_second_taxid':
      case 'e_core_tax_id':
      case 'borrower_tid':
      case 'borrower_core_tax_id':
        mask = '99-9999999';
        break;

      // Phone (###) ###-####
      case 'core_home_phone':
      case 'core_work_phone':
      case 'core_mobile_phone':
      case 'core_spouse_mobile':
      case 'core_second_phone':
      case 'core_re_company_phone':
      case 'core_att_company_phone':
      case 'core_lender_phone1':
      case 'core_lender_phone2':
      case 'core_association_phone':
      case 'core_association_phone2':
      case 'core_funds_specific_escrow_ph':
      case 'core_mail_phone':
      case 'core_pickup_phone':
        mask = '(999) 999-9999';
        break;

      // Zip Code
      case 'core_att_company_zip':
      case 'core_mailing_zip':
      case 'core_post_zip':
      case 'core_other_mail_zip':
      case 'core_mail_zip':
        mask = '99999';
        break;

      // Credit CVV Code
      case 'core_cc_3v':
      case 'core_cc_mc':
      case 'core_cc_d':
      case 'core_cc_3_mc':
      case 'core_cc_3_v':
        mask = '999';
        break;

      case 'core_cc_4':
        mask = '9999';
        break;

      // Credit Expiration (mm/YYYY)
      case 'core_cc_exp':
        mask = '99/9999';
        break;

      case 'core_cc_number':
        mask = '9999999999999999';
        mask_char = ' ';
        break;
    }

    return { mask, mask_char };
  };

  const handleChange = (event) => {
    setTouched(element.element_id);
    if (
      element.element_name == 'core_assoication_dues' ||
      element.element_name == 'core_association_dues2'
    ) {
      setFormValue({ id: element.element_id, value: event.target.value.replace(/[^0-9.]/g, '') });
      setValue(event.target.value.replace(/[^0-9.]/g, ''));
    } else {
      setFormValue({ id: element.element_id, value: event.target.value });
      setValue(event.target.value);
    }
  };
  const { mask, mask_char } = getElementMask(element);

  const handleError = async () => {
    setIsLoading(true);
    const { isError, message } = errorChecker(
      element,
      findIsTouched(element.element_id) ||
        SSN_FIELDS.find((field) => element?.element_name?.startsWith(field))
        ? formValue
        : element.user_value,
      mask
    );
    if (!isError) {
      removeError(element.element_id);
    } else {
      setError({ id: element.element_id, error: true, error_message: message });
    }
    if (dlDetails?.status === 'approved' && value) {
      checkDlError();
    }

    // Below logic is to verify bank details. We don't have lyon's production credentials so commenting out this feature. Comment ID for future reference : COMMENTED_CODE_LYONS_2612
    // if (
    //   element.label.replace(':', '') === ACCOUNT_NAME ||
    //   element.label.replace(':', '') === ROUTING_NAME ||
    //   element.label.replace(':', '') === ROUTING_NAME_2 ||
    //   element.label.replace(':', '') === NAME_OF_ACCOUNT_NAME
    // ) {
    //   await verifyBankAccount();
    // }
    setIsLoading(false);
  };

  const getFieldValue = (id, section) =>
    getFormValue(id) || findElement(section, id)?.user_value || '';

  const verifyBankAccount = async () => {
    const info = findBankDetails();
    const account_number = getFieldValue(
      info?.bankDetails?.account_number?.element_id,
      info?.sectionDetails?.section
    );

    const routing_number = getFieldValue(
      info?.bankDetails?.routing_number?.element_id,
      info?.sectionDetails?.section
    );

    const name_on_account = getFieldValue(
      info?.bankDetails?.name_on_account?.element_id,
      info?.sectionDetails?.section
    );
    if (
      account_number &&
      String(account_number)?.length &&
      routing_number &&
      String(routing_number)?.length &&
      name_on_account?.length
    ) {
      if (
        bankDetails?.[info?.bankDetails?.bank_type] &&
        isSame(
          { account_number, routing_number, name_on_account },
          bankDetails?.[info?.bankDetails?.bank_type]
        )
      )
        return;
      const res = await verifyBankDetails(
        {
          order_participant_id: authData?.id,
          account_number,
          routing_number,
          name_on_account,
          bank_type: info?.bankDetails?.bank_type,
        },
        authData?.token
      );

      // save response in redux
      if (res?.response?.lyons_response) {
        let dataToStore = {
          success: res?.success,
          attempts: res?.response?.number_of_attempt,
          account_number,
          routing_number,
          name_on_account,
          verified_field: res?.success ? info.bankDetails.account_number.element_id : '',
          associated_fields: res?.success
            ? [
                info.bankDetails.bank_state.element_id,
                info.bankDetails.bank_city.element_id,
                info.bankDetails.bank_name.element_id,
              ]
            : [],
        };

        dispatch(
          addBankDetails({
            tx_id: code,
            type: info?.bankDetails?.bank_type,
            data: dataToStore,
          })
        );
      }

      if (res?.success) {
        toast.success(
          `Your ${
            info?.bankDetails?.bank_type && info?.bankDetails?.bank_type !== 'main'
              ? info?.bankDetails?.bank_type
              : ''
          } bank account has been successfully verified. `
        );
        // populate bank info in fields
        populateBankInfo(info, res?.response?.lyons_response);
      } else {
        toast.error(res.message);
      }

      // add source data for admin verification
      let queJson = _.cloneDeep(question);
      const currentActiveSection = question?.sections?.findIndex(
        (section) => section?.section_label === info?.sectionDetails?.section?.section_label
      );
      const newJson = updateSourceData(queJson, currentActiveSection, {
        [info.bankDetails.name_on_account.element_id]: res?.success ? name_on_account : 'no',
        [info.bankDetails.routing_number.element_id]: res?.success ? routing_number : 'no',
        [info.bankDetails.account_number.element_id]: res?.success ? account_number : 'no',
        [info.bankDetails.bank_city.element_id]: res?.success
          ? res?.response?.lyons_response?.primaryInstitution?.City
          : 'no',
        [info.bankDetails.bank_state.element_id]: res?.success
          ? res?.response?.lyons_response?.primaryInstitution?.State
          : 'no',
        [info.bankDetails.bank_name.element_id]: res?.success
          ? res?.response?.lyons_response?.primaryInstitution?.Name
          : 'no',
      });
      if (newJson) {
        const res = await verifyElement(
          code,
          {
            cached_questions_json: {
              question: _.cloneDeep(newJson),
            },
            is_completed: false,
          },
          authData?.token
        );
        if (res?.success) {
          await getQuestionData();
        } else {
          return toast.error('Something went wrong!');
        }
      }

      if (res?.success && authData?.is_bank_account_client) {
        await saveFormDataPHH(false);
      }
    }
  };

  const isSame = (newData, oldData) => {
    return (
      newData?.account_number === oldData?.account_number &&
      newData?.routing_number === oldData?.routing_number &&
      newData?.name_on_account === oldData?.name_on_account
    );
  };
  const populateBankInfo = (info, lyonsResponse) => {
    setFormValue({
      id: info.bankDetails.bank_city.element_id,
      value: lyonsResponse?.primaryInstitution?.City,
    });
    setTouched(info.bankDetails.bank_city.element_id);
    removeError(info.bankDetails.bank_city.element_id);

    setFormValue({
      id: info.bankDetails.bank_state.element_id,
      value: lyonsResponse?.primaryInstitution?.State,
    });
    setTouched(info.bankDetails.bank_state.element_id);
    removeError(info.bankDetails.bank_state.element_id);

    setFormValue({
      id: info.bankDetails.bank_name.element_id,
      value: lyonsResponse?.primaryInstitution?.Name,
    });
    setTouched(info.bankDetails.bank_name.element_id);
    removeError(info.bankDetails.bank_name.element_id);
  };

  const findBankDetails = () => {
    let bankDetails = {};
    const sectionDetails = findSectionAndGroupForElement(question?.sections, element?.element_id);
    if (sectionDetails?.group) {
      const parentElem = findParentElement(sectionDetails?.group, element?.element_id);
      const elems = parentElem?.parent
        ? parentElem?.parent?.options?.find((opt) => opt?.option_id === parentElem?.optionId)
            ?.elements
        : sectionDetails?.group?.elements;
      for (const elem of elems) {
        if (elem.label.replace(':', '') === ACCOUNT_NAME) bankDetails.account_number = elem;
        if (
          elem.label.replace(':', '') === ROUTING_NAME ||
          elem.label.replace(':', '') === ROUTING_NAME_2
        )
          bankDetails.routing_number = elem;
        if (elem.label.replace(':', '') === NAME_OF_ACCOUNT_NAME)
          bankDetails.name_on_account = elem;
        if (elem.label.replace(':', '') === BANK_CITY_NAME) bankDetails.bank_city = elem;
        if (elem.label.replace(':', '') === BANK_STATE_NAME) bankDetails.bank_state = elem;
        if (elem.label.replace(':', '') === BANK_NAME_NAME) bankDetails.bank_name = elem;
      }
      if (!parentElem) {
        bankDetails.bank_type = 'main';
      } else if (
        parentElem?.parent?.label &&
        parentElem?.parent?.label?.toLowerCase()?.includes('intermediary')
      ) {
        bankDetails.bank_type = 'intermediary';
      } else if (
        parentElem?.parent?.label &&
        parentElem?.parent?.label?.toLowerCase()?.includes('correspondent')
      ) {
        bankDetails.bank_type = 'correspondent';
      } else {
        bankDetails.bank_type = 'main';
      }
      return { bankDetails, sectionDetails };
    }
  };

  // const revalidateAddress = async () => {
  //   setIsLoading(true);
  //   const userAddr = getUserAddress();
  //   if (Object.keys(userAddr || {})?.length && dlDetails?.transaction_response?.CustomerAddress) {
  //     const addressResult = await matchAddress(
  //       dlDetails?.transaction_response?.CustomerAddress,
  //       userAddr,
  //       authData?.token
  //     );
  //     if (addressResult?.[MAILING_ADDRESS_ADDRESS]) {
  //       toast.error('Mailing address does not match the address on your driving license.');
  //     } else {
  //       toast.success('Mailing address has been successfully validated with the driving license.');
  //     }
  //     let data = {
  //       ...dlDetails,
  //       errors: addressResult,
  //     };
  //     dispatch(addDlDetails({ tx_id: code, data: { ...data } }));
  //   }
  //   setIsLoading(false);
  // };

  const fetchName = () => {
    if (
      isBuyer
        ? buyerNameVal()
        : dlDetails?.transaction_response?.[mapping?.[element?.element_id]]?.split(' ')?.[0]
    ) {
      dispatch(
        addDlDetails({
          tx_id: code,
          data: {
            ...dlDetails,
            review: { ...(dlDetails?.review || {}), [element.element_id]: true },
          },
        })
      );
      setFormValue({
        id: element.element_id,
        value: isBuyer
          ? buyerNameVal()
          : dlDetails?.transaction_response?.[mapping?.[element?.element_id]]?.split(' ')?.[0],
      });
      setTouched(element.element_id);
    }
  };

  const removeDlError = () => {
    dispatch(
      addDlDetails({
        tx_id: code,
        data: {
          ...dlDetails,
          review: { ...(dlDetails?.review || {}), [element.element_id]: true },
        },
      })
    );
    removeError(element.element_id);
  };

  // We don't have lyon's production credentials so commenting out this feature. Comment ID for future reference : COMMENTED_CODE_LYONS_2612
  // if (hiddenFields?.includes(element.label?.replace(':', '')) && !element.value_from_bank_source) {
  //   return;
  // }
  if (element.element_name === TITLE_VESTED_NAME && !element.user_value) return;

  // const regenerateInvitationLink = async () => {
  //   setIsLoading(true);
  //   const res = await createInvitation(authData?.token, {
  //     participant_uuid: code,
  //     client_id: companyDetails.client_id,
  //     client_office_id: companyDetails.client_office_id,
  //     second_time: true,
  //   });
  //   if (res.success) {
  //     dispatch(
  //       addDlDetails({
  //         tx_id: code,
  //         data: {},
  //       })
  //     );
  //     dispatch(addQRDetails({ tx_id: code, data: { ...res.response, regenerated: true } }));
  //   }
  //   setIsLoading(false);
  // };

  // const fetchLastImages = async () => {
  //   setIsLoading(true);
  //   const res = await addUserConcent(authData?.token, {
  //     participant_uuid: code,
  //   });
  //   if (res.success) {
  //     dispatch(
  //       addDlDetails({
  //         tx_id: code,
  //         data: {
  //           ...dlDetails,
  //           transaction_response: { ...dlDetails?.transaction_response, user_consent: true },
  //           errors: {
  //             [MAILING_ADDRESS_ADDRESS]: false,
  //             [MAILING_ADDRESS_STATE]: false,
  //             [MAILING_ADDRESS_CITY]: false,
  //             [MAILING_ADDRESS_ZIP]: false,
  //             state: 'null',
  //             moby_address: '',
  //           },
  //         },
  //       })
  //     );
  //   }
  //   setIsLoading(false);
  // };

  const isVerifiedField =
    element.label.replace(':', '') === ACCOUNT_NAME &&
    element.value_from_bank_source &&
    element.value_from_bank_source !== 'no';

  const isDisabledField =
    FIELDS_TO_DISABLE?.includes(element.label.replace(':', '')) &&
    element.value_from_bank_source &&
    element.value_from_bank_source !== 'no';

  let removeRequiredValidations = false;
  if (startsWithAny(element.element_name, FIELDS_CHECK_VALIDATIONS)) {
    const splits = element.element_name.split('_');
    const loanNum = splits[splits?.length - 1];
    const docData = getDocument(LOAN_UPLOAD_NAME + `_${loanNum}`);
    if (docData?.[0]?.file_name?.length) {
      removeError(element.element_id);
      removeRequiredValidations = true;
    }
  }

  const checkConditionForAdditionalSeller = () => {
    return (
      +participantNo === +element?.participant_no &&
      element?.element_name ===
        authData?.participant_type_name?.toLowerCase() + DL_FIELD_NUMBER + `_${participantNo}`
    );
  };

  return (
    <>
      {isLoading ? <Loader /> : ''}

      {open && (
        <MobyUploadModal
          open={open}
          setOpen={setOpen}
          url={qrDetails?.invitation_url}
          code={code}
        />
      )}

      {(
        isAdditionalParticipant
          ? checkConditionForAdditionalSeller()
          : primaryDlFields.includes(element.element_id)
      ) ? (
        Object.keys(dlDetails?.transaction_response || {})?.length ? (
          <>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <label className='f-14'>
                {dlDetails?.transaction_response?.IdentificationTypeId &&
                ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                  ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                  : t('govIdHeader')}{' '}
                {dlDetails?.transaction_response?.Approved ? (
                  <span className='color-s'>
                    ({t('verified')}{' '}
                    <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />)
                  </span>
                ) : (
                  <></>
                )}
              </label>{' '}
            </Grid>

            <Grid xs={24} md={24} item={true} className='mt-4 mb-4'>
              <ImageList cols={window.innerWidth < 500 ? 1 : 2} gap={20} variant='quilted'>
                {dlDetails?.dl_image_1?.length ? (
                  <ImageListItem key={dlDetails?.dl_image_1}>
                    <img
                      src={`${dlDetails?.dl_image_1}`}
                      alt='gov-id-front'
                      className='driving-license'
                    />
                    <ImageListItemBar
                      title={`${
                        dlDetails?.transaction_response?.IdentificationTypeId &&
                        ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          : 'Government Issued ID'
                      } ${dlDetails?.dl_image_2?.length ? '[Front]' : ''}`}
                      // subtitle={
                      //   `${
                      //     dlDetails?.transaction_response?.FirstName
                      //       ? dlDetails?.transaction_response?.FirstName
                      //       : ''
                      //   }` +
                      //   ' ' +
                      //   `${
                      //     dlDetails?.transaction_response?.FirstSurname
                      //       ? dlDetails?.transaction_response?.FirstSurname
                      //       : ''
                      //   }`
                      // }
                    />
                  </ImageListItem>
                ) : (
                  ''
                )}
                {dlDetails?.dl_image_2?.length ? (
                  <ImageListItem key={dlDetails?.dl_image_2}>
                    <img
                      src={`${dlDetails?.dl_image_2}`}
                      alt='giv-id-front'
                      className='driving-license'
                    />
                    <ImageListItemBar
                      title={`${
                        dlDetails?.transaction_response?.IdentificationTypeId &&
                        ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          ? ID_MAPPING?.[dlDetails?.transaction_response?.IdentificationTypeId]
                          : 'Government Issued ID'
                      } [Back]`}
                      // subtitle={
                      //   `${
                      //     dlDetails?.transaction_response?.FirstName
                      //       ? dlDetails?.transaction_response?.FirstName
                      //       : ''
                      //   }` +
                      //   ' ' +
                      //   `${
                      //     dlDetails?.transaction_response?.FirstSurname
                      //       ? dlDetails?.transaction_response?.FirstSurname
                      //       : ''
                      //   }`
                      // }
                    />
                  </ImageListItem>
                ) : (
                  ''
                )}
              </ImageList>
            </Grid>
          </>
        ) : (
          <>
            <Grid xs={24} md={24} item={true} className='mt-4'>
              <label className=''>{t('qrNote')}</label>
            </Grid>
            {qrDetails?.invitation_url ? (
              <Grid container className={qrDetails?.isError ? 'dl-error-container' : ''}>
                {' '}
                <Grid
                  xs={12}
                  md={5.5}
                  item={true}
                  className='d-flex justify-center align-items-center'
                >
                  <div
                    onClick={() => {
                      if (isMobile) {
                        window.open(qrDetails?.invitation_url);
                      } else {
                        setOpen(true);
                      }
                    }}
                    className='f-15 fw-650 color-p cursor-pointer'
                    style={{ wordBreak: 'break-word', textDecoration: 'underline' }}
                  >
                    {t('mobyUpload')}
                  </div>
                </Grid>
                <Grid xs={12} md={1} item={true} className='mt-4'>
                  <Divider orientation='vertical'>OR</Divider>
                </Grid>
                <Grid xs={12} md={5.5} item={true} className='mt-4'>
                  <div className='color-p f-13 fw-550'></div>
                  <div className='d-flex justify-center'>
                    <img
                      style={{ width: '250px', height: 'auto' }}
                      src={qrDetails?.qrcode_url ?? ''}
                      alt='qr-code'
                    />
                  </div>
                </Grid>
              </Grid>
            ) : !isLoading ? (
              <>
                <Alert
                  severity='warning'
                  className='f-13 mb-8 mt-8 fw-450 w-100p'
                  style={{ padding: '2px 10px' }}
                >
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      {t('invitationError')}
                    </Grid>
                  </Grid>
                </Alert>
              </>
            ) : (
              ''
            )}
          </>
        )
      ) : (
        ''
      )}
      <Grid item xs={12} md={6} className='grid-container plt-8'>
        <Grid xs={12} md={12} item={true}>
          <label
            dangerouslySetInnerHTML={{
              __html: languageForLabel
                ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  ? translations?.[languageForLabel]?.elements?.[element.element_id]
                  : element?.label
                : element?.label,
            }}
          />
          <label className='required'>
            {element.required && !removeRequiredValidations && '*'}
          </label>{' '}
          {element?.help_note ? (
            <Tooltip title={element?.help_note}>
              {' '}
              <Help className='f-14 mt-minus-2 cursor-pointer' />
            </Tooltip>
          ) : (
            ''
          )}
          {isVerifiedField ? (
            <label>
              <span className='color-s ml-4'>
                ({t('verified')} <VerifiedUser style={{ fontSize: '14px', marginBottom: '-2px' }} />
                )
              </span>
            </label>
          ) : (
            <></>
          )}
        </Grid>
        <Grid xs={12} md={12} item={true} className='mt-4 t-field'>
          {mask == '' ? (
            placeholders.length > 0 && !element.user_value ? (
              <Autocomplete
                id='free-solo-demo'
                freeSolo
                disableClearable
                options={placeholders}
                onBlur={handleError}
                value={value}
                onInputChange={(event, newValue) => {
                  setTouched(element.element_id);
                  setValue(newValue);
                  setFormValue({ id: element.element_id, value: newValue });
                }}
                disabled={
                  disabled ||
                  element.element_name === TITLE_VESTED_NAME ||
                  isDisabledField ||
                  element?.disabled
                }
                renderInput={(params) => {
                  params.inputProps.autoComplete = 'off';
                  params.inputProps.role = 'presentation';
                  return (
                    <TextField
                      {...params}
                      className='full-width'
                      error={error && error_type !== 'warning'}
                      // helperText={error_type !== 'warning' ? error_message : ''}
                      disabled={
                        disabled ||
                        element.element_name === TITLE_VESTED_NAME ||
                        isDisabledField ||
                        element?.disabled
                      }
                    />
                  );
                }}
              />
            ) : (
              <TextField
                error={error && error_type !== 'warning'}
                id='outlined-basic'
                className='full-width'
                variant='outlined'
                onChange={handleChange}
                onBlur={handleError}
                value={value}
                inputProps={{
                  role: 'presentation',
                  autoComplete: 'off',
                }}
                // helperText={error_type !== 'warning' ? error_message : ''}
                disabled={
                  disabled ||
                  element.element_name === TITLE_VESTED_NAME ||
                  isDisabledField ||
                  element?.disabled
                }
              />
            )
          ) : (
            <InputMask
              mask={mask}
              value={value}
              maskChar={mask_char}
              onChange={handleChange}
              onBlur={handleError}
              disabled={
                disabled ||
                element.element_name === TITLE_VESTED_NAME ||
                isDisabledField ||
                element?.disabled
              }
            >
              {() => (
                <TextField
                  error={error && error_type !== 'warning'}
                  className='full-width'
                  variant='outlined'
                  // helperText={error_type !== 'warning' ? error_message : ''}
                  inputProps={{
                    role: 'presentation',
                    autoComplete: 'off',
                  }}
                  disabled={
                    disabled ||
                    element.element_name === TITLE_VESTED_NAME ||
                    isDisabledField ||
                    element?.disabled
                  }
                />
              )}
            </InputMask>
          )}
          {!disabled &&
          !isAdditionalParticipant &&
          (element.element_id === SELLER_FIRST_NAME ||
            element.element_id === SELLER_LAST_NAME ||
            element.element_id === BUYER_FULL_NAME_ID) &&
          error &&
          error_type === 'warning' ? (
            <div className='color-w f-12'>
              {t('nameErrorMsg')}{' '}
              <span onClick={fetchName} className='ml-2 text-underline cursor-pointer'>
                {t('yes')}
              </span>
              <span onClick={removeDlError} className='ml-8 text-underline cursor-pointer'>
                {t('no')}
              </span>
            </div>
          ) : (
            ''
          )}
        </Grid>
        {/* {element.element_id === AGENT_EMAIL_FIELD_ID ? (
        <div className='f-12 pl-8 fw-500 color-g'>
          Note : We will utilize this email address to contact the agent and collect additional
          information.
        </div>
      ) : (
        ''
      )} */}
      </Grid>
      {/* {element.element_id === MAILING_ADDRESS_ZIP &&
      dlDetails?.errors?.[MAILING_ADDRESS_ADDRESS] ? (
        <div className='d-flex justify-end w-100p'>
          <Button
            variant='outlined'
            style={{ display: 'flex', height: '30px', marginBottom: '12px' }}
            onClick={() => {
              revalidateAddress();
            }}
            disabled={disabled}
          >
            Revalidate Address
          </Button>
        </div>
      ) : (
        ''
      )} */}
    </>
  );
};
export default TextFieldInput;
